import React, { Component } from 'react'

export default class Merchant extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <div>
        <button onClick={() => {
          this.props.toggleView("eater")}}>Click</button>
      </div>
    )
  }
}
