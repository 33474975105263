import React, { Component } from 'react';
import './App.css';
import Eater from './views/EaterView.js';
import Merchant from './views/MerchantView.js';
import Loading from './views/Loading.js';

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      view: 'eater',
      isLoaded:false,
    }

    //binders
    this.toggleView = this.toggleView.bind(this);
    this.renderView = this.renderView.bind(this);
    this.setIsLoaded = this.setIsLoaded.bind(this);
  }

  componentDidMount() {
    setInterval(this.setIsLoaded, 3000)
  }

  setIsLoaded(){
    const { isLoaded } = this.state;

    if(!isLoaded){
      this.setState({
        isLoaded:true,
      })
    }

  }

  toggleView(switchedView){
    const { view } = this.state;

    this.setState({
      view: switchedView
    })
  }

  renderView() {
    const { view } = this.state;

    switch (view){
      case 'eater':
        return(
          <div>
            <Eater
              view={this.state.view}
              toggleView={this.toggleView}
            />
          </div>
        )
      break;

      case 'merchant':
        return (
          <div>
            <Merchant
              view={this.state.view}
              toggleView={this.toggleView}
            />
          </div>
        )
    }
  }

  render() {
    const { isLoaded } = this.state;

    if(isLoaded){

      return this.renderView()

    }
      return(
          <>
            <Loading />
          </>
      )



  }
}
