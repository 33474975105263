import React, { Component } from 'react';
import '../styles/EaterView.css';
import Navbar from './Navbar.js';
import Merchants from './Merchants';
import Start from './Start.js';
import PlaceOrder from './PlaceOrder.js';
import Loyalty from './Loyalty.js';
import Contact from './Contact.js';
import About from './About.js';
import Values from './Values.js';
import Team from './Team.js';
import Footer from './Footer.js';
import {
  FaRegArrowAltCircleDown,
  FaShoppingBag,
  FaRegMoneyBillAlt,
  FaMoneyBillWave,
  FaUtensils
} from 'react-icons/fa';

export default class Eater extends Component {
  constructor(props) {
    super(props)

    this.state = {
      view: 'eater',
      aboutVisible: false,
      valuesVisible: false,
      teamVisible: false,
    }

    //binders
    this.switchView = this.switchView.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
    this.toggleValues = this.toggleValues.bind(this);
    this.toggleTeam = this.toggleTeam.bind(this);

  }

  switchView(view){
    this.setState({
      view
    })
  }

  toggleAbout() {
    const { aboutVisible } = this.state;

    this.setState(prevState => ({
      aboutVisible: !prevState.aboutVisible
    }))
  }

  toggleValues() {
    const { valuesVisible } = this.state;

    this.setState(prevState => ({
      valuesVisible: !prevState.valuesVisible
    }))
  }

  toggleTeam() {
    const { teamVisible } = this.state;

    this.setState(prevState => ({
      teamVisible: !prevState.teamVisible
    }))
  }

  render() {
    const { view, aboutVisible, valuesVisible, teamVisible } = this.state;

    return (
      <div className='eaters-background'>

        <Navbar />

        <div className='eater-landing'>
          <img className='eater-landing-background' src={process.env.PUBLIC_URL + '/assets/foodPattern.png'} alt="Food Pattern"/>


          <div className='phone-container'>
            <div className='phone-circle-yellow' style={{ backgroundColor: (view == 'eater') ? '#D8A600' : '#f1f3f2' }}>
              <div className='inner-yellow-circle' style={{ borderColor: (view == 'eater') ? '#FFC609' : 'rgba(0,0,0,.15)' }}></div>
            </div>
            <div className='phone-circle-red' style={{ backgroundColor: (view == 'eater') ? '#1EA375' : '#20a477' }}>
              <div className='inner-red-circle'style={{ borderColor: (view == 'eater') ? '#00FFA7' : '#79c8ac' }}></div>
            </div>
            <div className='phone-circle-green' style={{ backgroundColor: (view == 'eater') ? '#CB171D' : '#79c8ac' }}>
              <div className='inner-green-circle' style={{ borderColor: (view == 'eater') ? '#FF0009' : '#20a477' }}></div>
            </div>
            <img src={ (view == 'eater') ? process.env.PUBLIC_URL + '/assets/landingPhone.png' : process.env.PUBLIC_URL + '/assets/phoneCompanion.png' } className={ `landing-phone ${ (view == "merchant") && "skew" }`} alt="Mockup Phone"/>
            <div className='view-btns-container'>
              <div onClick={
                () => {
                  this.switchView('eater');
                }
              } className={ `merchant-btn ${ (view == "eater") && "selected" }`}>Eater's View</div>
              <div className='merchant-btn' onClick={
                () => {
                  this.switchView('merchant');
                }
              } className={ `merchant-btn ${ (view == "merchant") && "selected" }`}>Merchant's View</div>
            </div>
          </div>

          <div className='text-section'>

            <div className='landing-title'>
              Voor <span>alles</span> <br/>
              wat je lust
            </div>

            <div className='divider'></div>

            <div className='landing-description'>
              For everything you crave, like a home-made <br/> burger to a
              delightful dessert, delivered to you.
            </div>

            <div className='landing-download-btn-wrapper'>
              <div className={ `landing-download-btn`}  style={{ backgroundColor: (view == 'eater') ? '#2F9967' : '#20a477' }} onClick={() => {
                const url = (view == 'eater') ? 'https://ride-eats.com/download/download' : 'https://ride-eats.com/download_companion/download'

                window.open(url, '_blank', 'noopener,noreferrer');
              }}>
                <FaRegArrowAltCircleDown/>
                <span className='landing-download-btn-text'>Download {  (view == 'eater') ? 'Ride Eats' : 'Eats Companion' }</span>
              </div>
            </div>

            <div className='features-list'>

              <div className='feature'>
                <div className='feature-icon'>
                  <FaShoppingBag style={{height:'20px'}}/>
                </div>
                <div className='feature-text'>
                  <div className='feature-title'> Become a Merchant</div>
                  <div className='feature-description'>Contact us to join our platform for <br/> free and start accepting orders.</div>
                </div>
              </div>

              <div className='feature'>
                <div className='feature-icon'>
                  <FaMoneyBillWave style={{height:'20px'}}/>
                </div>
                <div className='feature-text'>
                  <div className='feature-title'> Become a Biker</div>
                  <div className='feature-description'>Contact us to become a biker and <br/> start earning on your own schedule.</div>
                </div>
              </div>

              <div className='feature'>
                <div className='feature-icon'>
                  <FaUtensils style={{height:'20px'}}/>
                </div>
                <div className='feature-text'>
                  <div className='feature-title'> Enjoy your Food</div>
                  <div className='feature-description'>Relax and enjoy the food you <br/> ordered, you deserve it. Bon Appétit.</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='eater-merchants'>
          <Merchants/>
        </div>

        <div className='start-section'>
          <Start/>
        </div>

        <div className='place-order'>
          <PlaceOrder/>
        </div>

        <div className='loyalty-section'>
          <Loyalty/>
        </div>

        <div className='contact-section'>
          <Contact/>
        </div>

        <div className={`about-section ${ (aboutVisible) ? 'opened' : '' }`}>
          <About
            toggleAbout = {this.toggleAbout}
            aboutVisible = {this.state.aboutVisible}
          />
        </div>

        <div className={`values-section ${ (valuesVisible) ? 'opened' : '' }`}>
          <Values
            toggleValues = { this.toggleValues }
            valuesVisible = { this.state.valuesVisible }
          />
        </div>

        <div className={`team-section ${ (teamVisible) ? 'opened' : '' }`}>
          <Team
            toggleTeam = { this.toggleTeam }
            teamVisible = { this.state.teamVisible }
          />
        </div>

        <Footer
          toggleValues = { this.toggleValues }
          toggleAbout = {this.toggleAbout}
          toggleTeam = {this.toggleTeam}
        />

      </div>
    )
  }
}
