import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import '../styles/Contact.css';
import mapStyles from '../constants/mapStyles.js';
import { FaMapMarkerAlt } from 'react-icons/fa';

export default class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mapOptions: {
        disableDefaultUI: true,
        styles: mapStyles.dark,
        scaleControl: false,
      },
      center:{
        lat: 5.831165,
        lng:-55.189356
      },
      zoom: 17,
    }
  }

  render() {
    return (
      <div className='contact-wrapper'>
        <div className='map-container'>
          <GoogleMapReact
            bootstrapURLKeys={{key: 'AIzaSyByicflh_u69XeDmmW8Q0ugl2N_Bj8pnCs'}}
            defaultCenter={ this.state.center }
            defaultZoom={ this.state.zoom }
            options={this.state.mapOptions}
          >

            <img
              className='marker'
              src={process.env.PUBLIC_URL + '/assets/RE_logoWhite.png'}
              lat= {5.831165}
              lng= {-55.189356}
             />

          </GoogleMapReact>
        </div>

        <div className='contact-info-wrapper'>
          <div className='contact-info-header'>
            Contact <span>Us</span>
          </div>

          <div className='contact-info-container'>
            <div className='contact-info1'>
              <div className='contact-header'>
                Our address
              </div>

              <div className='contact-body'>
                Paramaribo <br/>
                Nadiastraat 17
              </div>
            </div>

            <div className='contact-info-2'>
              <div className='contact-header'>
                Our info
              </div>

              <div className='contact-body'>
                info@ride-eats.com <br/>
                1975
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
