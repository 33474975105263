import React, { Component } from 'react';
import '../styles/Loading.css';
import Lottie from 'react-lottie';
import animationData from '../lotties/loading.json'

export default class Loading extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div className='loading-background'>
        <div className='loading-container'>
          <img src={process.env.PUBLIC_URL + '/assets/RE_logoWhite.png'} />
          <Lottie width={250} height={100} options={defaultOptions} isClickToPauseDisabled={true} />
        </div>
      </div>
    )
  }
}
