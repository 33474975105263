import React, { Component } from 'react';
import "../styles/Team.css";

export default class About extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { teamVisible } = this.props;

    if (teamVisible){

      return (
        <div className='team-wrapper'>
          <div className='circle-wrapper-one'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>

          <div className='circle-wrapper-two'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>


          <div className='team-container'>
            <div className='team-top-row' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/team.jpeg' })`}}>

            </div>
            <div className='team-bottom-row' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/pattern.png' })`}}>
              <div className='about-close-btn' onClick={() => {this.props.toggleTeam()}}>
                CLOSE
              </div>
            </div>
          </div>
        </div>
      )
    }
      return (
        <div></div>
      )

  }
}
