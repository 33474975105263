import React, { Component } from 'react';
import '../styles/Loyalty.css';
import myStyle from '../constants/constants.js'

export default class Loyalty extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <div className='loyalty-container'>

        <div className='loyalty-text-container'>
          <div className='loyalty-header'>Loyalty <span>Shop</span></div>
          <div className='loyalty-sub-header'>Eats® Loyalty Program</div>
          <div className='loyalty-main-text'>
            Earn points through <br/>
            purchases and redeem <br/>
            these for wonderful givebacks.
          </div>
          <div className='loyalty-divider'></div>
          <div className='loyalty-uitleg'>
            Each srd 100 will earn you 1 point. <br/>
            Points are calculated to two (2) decimals, so you <br/>
            won't lose anything.
          </div>
          <div className='loyalty-points-container'>
            <div className='top-bar'>
              You have saved <div className='RP'>RP</div> 19
            </div>
            <div className='points-bar-container'>
              <div className='points-circle'>19</div>
              <div className='points-divider'></div>
              <div className='points-circle' style={{background:'#E4E4E4', color: "#000"}}>20</div>
            </div>
            <div className='points-text-container'>
              <div className='points-header'>
                Order and save Points
              </div>
              <div className='points-body'>
                Order dishes from your favorite <br/>
                restaurants and save points for <br/>
                Coupons, Goodies & More.
              </div>
            </div>
            <div className='points-btn'>Next</div>
          </div>
        </div>

        <div className='loyalty-phone-container'>
          <div className='loyalty-circle'>
            <div className='loyalty-inner-circle'></div>
          </div>
          <img className='loyalty-phone' src={process.env.PUBLIC_URL + '/assets/loyaltyShop.png'} />
        </div>

      </div>

    )
  }
}
