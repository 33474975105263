import React, { Component } from 'react';
import '../styles/Start.css';
import myStyle from '../constants/constants.js';
import { FaUser, FaUsers, FaLock } from 'react-icons/fa';

export default class Start extends Component {
  constructor(props) {
    super(props)

    this.state = {
      method: 'whatsapp'
    }

    //binders
    this.switchMethod = this.switchMethod.bind(this);

  }

  switchMethod(newMethod){
    const { method } = this.state;

    this.setState({
      method : newMethod
    })

  }

  render() {
    const { method } = this.state;

    return (
      <div className='start-container'>
        Here's how you <span>start...</span>
        <div className='start-cards-container'>

          {/*Create Account Card */}
          <div className='create-account-card'>
            <div className='start-card-header'>
              Create an account
              <div className='create-card-description'>
                Fill in the form to create your account.
              </div>
            </div>

            {/* Input Start */}
            <div className='phone-number'>
              <div className='phone-number-container'>
                <div className='phone-number-icon'>
                  <img src={process.env.PUBLIC_URL + '/assets/suriname_flag.png'} className='flag' />
                </div>
              </div>
              <div className='number-preset'>
                +597
              </div>
              Phone number
            </div>

            <div className='create-card-input'>
              <div className='create-icon-container'>
                <div className='create-card-icon'>
                  <FaUser/>
                </div>
              </div>
              First Name
            </div>

            <div className='create-card-input'>
              <div className='create-icon-container'>
                <div className='create-card-icon'>
                  <FaUsers/>
                </div>
              </div>
              Last Name
            </div>

            <div className='create-card-input'>
              <div className='create-icon-container'>
                <div className='create-card-icon'>
                  <FaLock/>
                </div>
              </div>
              Password
            </div>

            <div className='create-card-input'>
              <div className='create-icon-container'>
                <div className='create-card-icon'>
                  <FaLock/>
                </div>
              </div>
              Retype Password
            </div>
            {/*Input End */}


            <div className='verification-method-container'>
              Send verification-code via
              <div className='verification-method-button-container'>
                <div onClick={() => {
                  this.switchMethod("whatsapp")
                }} className={ `verification-method-button ${ (method == "whatsapp") && "selected" }`}>WhatsApp</div>
                <div onClick={() => {
                  this.switchMethod("sms")
                }} className={ `verification-method-button ${ (method == "sms") && "selected" }`}>SMS</div>
              </div>
            </div>

            <div className='create-account-btn'>Create Account</div>
            <div className='have-account-btn'>I have an account</div>

          </div>

          <div className='connector'></div>

          {/*Verify Card */}
          <div className='verify-account-card'>
            <div className='start-card-header'>
              Verify your phone number
              <div className='create-card-description'>
                Insert the 4-digit number you received via SMS.
              </div>
            </div>

            <div className='create-card-input'>
              <div className='create-icon-container'>
                <div className='create-card-icon'>
                <FaLock/>
                </div>
              </div>
              Verification code
            </div>

            <div className='create-account-btn'>Verify phone number</div>
            <div className='have-account-btn'>Cancel</div>


          </div>

          <div className='connector'></div>

          {/*Login Card*/}
          <div className='login-card'>
            <div className='start-card-header'>
              Getting started
              <div className='create-card-description'>
                Log into your account to continue.
              </div>
            </div>

              <div className='phone-number'>
                <div className='phone-number-container'>
                  <div className='phone-number-icon'>
                    <img src={process.env.PUBLIC_URL + '/assets/suriname_flag.png'} className='flag' />
                  </div>
                </div>
                <div className='number-preset'>
                  +597
                </div>
                Phone number
              </div>

              <div className='create-card-input'>
                <div className='create-icon-container'>
                  <div className='create-card-icon'>
                    <FaLock/>
                  </div>
                </div>
                Password
              </div>

              <div className='create-account-btn'>Log In</div>
              <div className='have-account-btn'>Create an account</div>

          </div>

        </div>
      </div>
    )
  }
}
