import React, { Component } from 'react';
import '../styles/PlaceOrder.css';
import myStyle from '../constants/constants.js';
import Lottie from 'react-lottie';
import animationData from '../lotties/cart.json';

export default class PlaceOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderStep:'1',
      isStopped: false,
    }

    //binders
    this.selectStep = this.selectStep.bind(this);

  }

  selectStep(step) {
    const { orderStep } = this.state;

    this.setState({
      orderStep: step
    })

  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const { orderStep } = this.state;

    return (
      <div className='order-panel'>

        <div className='order-header'>
          Are you <span>hungry?</span>
          <div className='order-sub-header'>Here's how to order..</div>
        </div>

        <div className='order-body'>
          <div className='order-text-container'>
            <div className='order-text-header'>
              { (orderStep == "1") ? 'Select a Merchant' : (orderStep == "2") ? "Select a dish" : (orderStep == "3") ? "Add to cart" : "Checkout" }
            </div>
            <div className='order-text-description'>
              { (orderStep == "1") ? 'Choose from a wide variety of merchants.' : (orderStep == "2") ? 'Select a dish from your chosen merchant.' : (orderStep == "3") ? "Add your chosen dish to your cart." : "Fill in the checkout info and confirm your order!" }
            </div>
          </div>

          <div className='order-phone-container'>
            <img className='order-phone' src={ (orderStep == '1') ? process.env.PUBLIC_URL + '/assets/selectMerchant.jpg' : (orderStep == '2') ? process.env.PUBLIC_URL + '/assets/selectDish.jpg' : (orderStep == "3") ? process.env.PUBLIC_URL + '/assets/addToCart.jpg' : (orderStep =="4") ? process.env.PUBLIC_URL + '/assets/checkout.jpg' : "" } />
            <div className='order-btns-container'>
              <div onClick={
                () => {
                  this.selectStep('1');
                }
              } className={ `merchant-btn ${ (orderStep == "1") && "selected" }`}>1</div>
              <div className='merchant-btn' onClick={
                () => {
                  this.selectStep('2');
                }
              } className={ `merchant-btn ${ (orderStep == "2") && "selected" }`}>2</div>
              <div className='merchant-btn' onClick={
                () => {
                  this.selectStep('3');
                }
              } className={ `merchant-btn ${ (orderStep == "3") && "selected" }`}>3</div>
              <div className='merchant-btn' onClick={
                () => {
                  this.selectStep('4');
                }
              } className={ `merchant-btn ${ (orderStep == "4") && "selected" }`}>4</div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
