
const myStyle ={
  textHeading:{
    fontSize:"48px",
    fontWeight:"700",
    color:"white",
  },
  textBody: {
    fontSize: "16px",
    fontWeight: "500",
  },
  cardHeader: {
    fontSize: "16px",
    fontWeight: "700",
  },
  cardBody: {
    fontSize: "13px",
    fontWeight: "500",
    color:"#BEBEBE",
  },
}


export default myStyle;
