import React, { Component } from 'react'
import '../styles/Navbar.css';

export default class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <div className='navbar-wrapper'>
        <div className='navbar'>
          <div className='nav-logo' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/RE_logo.png' })`}}></div>
          <div className='nav-links-container'>
            <div className='nav-link' onClick={() => {
              const url = 'https://ride-eats.com/dashboard/';

              window.open(url,'_blank', 'noopener,noreferrer')
            }}>Merchant Manager</div>
          </div>
        </div>
      </div>
    )
  }
}
