import React, { Component } from 'react';
import "../styles/About.css";

export default class About extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { aboutVisible } = this.props;

    if (aboutVisible){

      return (
        <div className='about-wrapper'>
          <div className='circle-wrapper-one'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>

          <div className='circle-wrapper-two'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>


          <div className='about-container' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/pattern.png' })`}}>
            <div className='about-top-row'>
              <img className='about-logo' src={process.env.PUBLIC_URL + '/assets/RE_logoWhite.png'} />
              <div className='about-header'>About <span>Us</span> </div>
            </div>
            <div className='about-middle-row'>
              Inspired by the COVID-19 pandemic, we saw the opportunity to improve the current delivery proces via technology. Our core team decided to act on the opportunity thus the Ride Eats App was created. <br/> <br/>

              The Ride Eats app is the most versatile application for a huge variety of food and drinks in Suriname, from smoothies and sandwiches to soups and culinary pastas. Ride Eats is there for the small home cooks to the large chains as the most innovative online sales & delivery partner.
              <br/> <br/>
              We offer a great user experience through the most beautiful and innovative app ever for "everything you crave" in Suriname.
            </div>
            <div className='about-bottom-row'>
              <div className='about-close-btn' onClick={() => {this.props.toggleAbout()}}>
                CLOSE
              </div>
            </div>
          </div>
        </div>
      )
    }
      return (
        <div></div>
      )

  }
}
