import React, { Component } from 'react';
import '../styles/Values.css';

export default class Values extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { valuesVisible } = this.props;

    if (valuesVisible) {
      return (
        <div className='values-wrapper'>

          <div className='circle-wrapper-three'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>

          <div className='circle-wrapper-four'>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
            <div className='circle-row'>
              <div className='circle'/>
              <div className='circle'/>
              <div className='circle'/>
            </div>
          </div>

          <div className='values-container' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/pattern.png' })`}}>
            <div className='about-top-row'>
              <img className='about-logo' src={process.env.PUBLIC_URL + '/assets/RE_logoWhite.png'} />
              <div className='about-header'>Our Core <span>Values</span> </div>
            </div>

            <div className='values-middle-row'>
              <div className='diversity-container'>
                <div className='diversity-header'>Diversity</div>
                <div className='diversity-body'>We believe in the power of diversity of our people and services.</div>
              </div>

              <div className='reliability-container'>
                <div className='reliability-header'>Trustworthy</div>
                <div className='reliability-body'>We value reliability and respect as base of our relationships and collaborations.</div>
              </div>

              <div className='innovation-container'>
                <div className='innovation-header'>Innovation</div>
                <div className='innovation-body'>We strive for continuous innovation and improvement of our products and services.</div>
              </div>
            </div>

            <div className='about-bottom-row'>
              <div className='about-close-btn' onClick={() => {this.props.toggleValues  ()}}>
                CLOSE
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div></div>
    )
  }
}
