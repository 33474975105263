import React, { Component } from 'react';
import Snake from 'react-simple-snake';
import '../styles/Footer.css';

export default class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showGame: false,
    }

    this.showGame = this.showGame.bind(this);
    this.renderGame = this.renderGame.bind(this);
  }

  showGame(){
    const { showGame } = this.state;

    this.setState(prevState => ({
      showGame: !prevState.showGame
    }))
  }

  renderGame(){
    const { showGame } = this.state;


    if (showGame){
      return (
        <div className='game-container' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/pattern.png' })`}}>
          <Snake percentageWidth={50} snakeColor={'#2F9967'} />
          <div className='instructions-container'>
            <div className='instructions-header'>How to <span>play?</span></div>
            <div className='instructions-body'>
              Use W/A/S/D or the arrow keys to control the snake.

            </div>
          </div>
          <div className='close-game-container'>
            <div className='close-game-btn' onClick = {() => {
              this.showGame();
            }}>CLOSE</div>
          </div>
        </div>
      )
    } else{
      return null
    }
  }

  render() {
    const { showGame } = this.state;

    return (
      <div className='footer-container'>
        {(showGame) ? this.renderGame() : <div></div>}
        <div className='footer-top-section'>
          <div className='footer-logo' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/RE_logo.png' })`}}></div>
          <div className='animation-container'>
            <div className='cloud1'></div>
            <div className='light' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/light.png' })`}}></div>
            <div className='bromfiets' style={{backgroundImage:`url(${ process.env.PUBLIC_URL + '/assets/scooter.png' })`}} onClick={() => {
              this.showGame();
            }}></div>
          </div>
        </div>
        <div className='footer-middle-section'>
          <div className='links-container'>
            <div className='link-header'>Links</div>
            <a className='link' href='https://www.facebook.com/rideeatssuriname' target='_blank'>Facebook</a>
            <a className='link' href='https://www.instagram.com/ride_eats/' target='_blank'>Instagram</a>
            <a className='link' href='https://ride-eats.com/download/download' target='_blank'>Download</a>
          </div>

          <div className='about-links-container'>
            <div className='about-links-header'>About</div>
            <div className='link' onClick={() => {
              this.props.toggleAbout()
            }}>Ride Eats</div>
            <div className='link' onClick={() => {
              this.props.toggleValues()
            }}>Core Values</div>
            {/*<div className='link' onClick={() => {
              this.props.toggleTeam()
            }}>Our Team</div>*/}
          </div>
        </div>
        <div className='footer-bottom-section'>
          <div className='footer-bottom-text'>©2022 Ride Eats Suriname</div>
          <div className='footer-bottom-text'>Coded with ♥</div>
        </div>
      </div>
    )
  }
}
