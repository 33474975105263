import React, { Component } from 'react';
import '../styles/Merchants.css';
import myStyle from '../constants/constants.js';
import axios from 'axios';
import {
  FaCheckCircle,
  FaTimesCircle,
} from 'react-icons/fa';


export default class Merchants extends Component {
  constructor(props) {
    super(props)

    this.state = {
      merchants:[],
      randomMerchants:[]
    }

    //binders
    this.renderMerchants = this.renderMerchants.bind(this);
    this.getMerchants = this.getMerchants.bind(this);
    this.randomMerchants = this.randomMerchants.bind(this);
    this.isRestaurantOpen = this.isRestaurantOpen.bind(this);
  }

  componentDidMount() {
    this.getMerchants();
    console.log("Are you lost?");
  }

  getMerchants(){
    const { merchants } = this.state;
    const API_PATH = 'https://ride-eats.com/api/global_restaurants';


    axios.post(`${API_PATH}`,{},
    {
      crossdomain: true,
      headers: {
        'content-type':'application/json',
        'Accept': 'application/json',
        'api': '7qPnJBjC4DfNHJlo3Ho2',
      },

    }).then(result => {
      const { success, response, data} = result.data;

      this.setState({
        merchants: data.restaurants
      }, () => {
        this.randomMerchants()
      })

    }).catch(error => {
      console.log(error);
    })
  }

  randomMerchants(num = 8){
    const { merchants } = this.state;
    const randomized = [...merchants].sort(() => 0.5 - Math.random());
    const randomMerchants = randomized.slice(0, num);

    this.setState({ randomMerchants })
  }


  isRestaurantOpen(restaurant){
    var isOpen = false;

    //Get stuff
    var d = new Date();
    var n = d.getDay();
    var now = d.getHours() + "." + d.getMinutes();


    if(restaurant.open_raw == 'closed' || restaurant.closed_raw == 'closed'){
      isOpen = false;
    }else if(restaurant && restaurant.open_raw && restaurant.open_raw){

      var open = parseFloat((restaurant.open_raw).replace(':', '.'));
      var closed = parseFloat((restaurant.closed_raw).replace(':', '.'));

      // If ex. 12:00 - 09:00 or something
      if(closed <= open && now < closed){
        open = parseFloat( parseFloat(open) - 24.00 );
        closed = parseFloat( parseFloat(closed) + 24.00 );
      }


      if(open == 'always' || closed == 'always'){
        isOpen = true;
      }else{

        if (now > open && now < closed ) {
          isOpen = true;
        }

      }
    }


    return isOpen;
  }

  renderMerchants(){
    const { randomMerchants } = this.state;



    const merchants_render = randomMerchants.map((merchant, index) =>{
      const merchant_logo = merchant.logo;
      const merchant_name = merchant.name;
      const merchant_opening_hours = merchant.opening_hours_all;
      const merchant_address = merchant.location.address;
      const merchant_has_delivery = merchant.has_delivery;
      const merchant_has_pickup = merchant.has_pickup;
      const isOpen = this.isRestaurantOpen(merchant);

      return (
          <div key={merchant.id} style={myStyle.cardHeader} className='merchant-card'>
            <div className='card-header'>
              <div className='merchant-icon' style={{backgroundImage: `url(${merchant_logo})`}}></div>
              <div className='merchant-details'>
                <div className='merchant-name'>{merchant_name}</div>
                <div style={myStyle.cardBody} className='merchant-address'>{merchant_address}</div>
              </div>
            </div>
            <div className='card-body'>
              <div className='merchant-status-container'>
                <div className='merchant-status' style={{backgroundColor: (isOpen) ? '#2FC562' : '#c53131' }}>{(isOpen) ? 'OPEN' : 'CLOSED'}</div>
                <div className='merchant-times'> Open from {merchant_opening_hours}</div>
              </div>
              <div className='merchant-method-container'>
                <div>This merchant offers: </div>
                <div className='method-container'>
                  <div className='method' style={{background: (merchant_has_pickup) ? '#2FC562' : '#101010'}}>PICK-UP {(merchant_has_pickup) ? <FaCheckCircle className='method-icon'/> : <FaTimesCircle className='method-icon'/> }</div>
                  <div className='method' style={{background: (merchant_has_delivery) ? '#2FC562' : '#101010'}}>DELIVERY {(merchant_has_delivery) ? <FaCheckCircle className='method-icon'/> : <FaTimesCircle className='method-icon'/> }</div>
                </div>
              </div>
            </div>
          </div>
      )
    })


    return (
      <div className='merchant-carousel'>
        { merchants_render }
      </div>
    );
  }



  render() {
    return (
      <div className='merchants-container'>
        Meet some of Our <br/> <span>Merchants</span>
        <div className='merchant-card-container'>
          { this.renderMerchants() }
        </div>
      </div>
    )
  }
}
